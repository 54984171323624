<template>
  <div class="create-promocode-page">
    <BackTitle confirm-click @click="goBack"> Create promocode </BackTitle>
    <PromocodeForm
      :value="promocode"
      submitButtonText="Add"
      :isSubmitting="isSubmitting"
      @submit="save"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import PromocodeForm from "@/components/promocodes/PromocodeForm.vue";
import { NEW_PROMOCODE_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";

export default {
  name: "CreatePromocodePage",
  components: { PromocodeForm, BackTitle },
  data() {
    return {
      promocode: { ...NEW_PROMOCODE_MOCK },
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions({
      createPromocode: "promocodes/createPromocode",
    }),
    async save(data) {
      try {
        this.isSubmitting = true;
        await this.createPromocode(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Promocodes",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.create-promocode-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
